.firebaseui-title {
    text-align: center !important;
}
.mdl-button--raised.mdl-button--colored {
    background-color: #96e8a6 !important;
}
.mdl-button--primary.mdl-button--primary {
    color: #96e8a6 !important;
}
.mdl-textfield__label:after {
    background-color: #96e8a6 !important;
}
.footer {
    display: none !important;
}
