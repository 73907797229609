body {
    background-color: white;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
a,
a:hover {
    color: #484848;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}
a:focus,
button:focus {
    outline: none;
}
textarea {
    border-style: none;
    border-color: transparent;
    overflow: hidden;
    resize: none;
}
.katex-mathml {
    display: none;
}
